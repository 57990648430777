<template>
  <b-card class="mb-2">
    <b-row class="no-gutters align-items-center">
      <b-col>
        <div class="font-weight-bold">{{ project.name }}</div>
        <div v-if="branch">
          {{ $t("branch") + ":" }}
          <span class="font-weight-bold">
            {{ branchName }}
          </span>
        </div>
        <div>
          {{ $t("page.clientProjects.projectStatus") }}
          <span class="font-weight-bold text-uppercase">
            {{ project.status }}
          </span>
        </div>
      </b-col>
      <b-col
        v-if="project.status === 'closed' && !isPredictUser"
        class="col-auto mr-2"
      >
        <b-button size="sm" variant="outline-primary" @click="preview">
          Preview
          <!-- {{ $t("button.details") }} -->
        </b-button>
      </b-col>
      <!-- <b-col v-else class="col-auto mr-2">
        <span style="font-size: 0.8rem" class=" text-red">
          No analysis file
        </span>
      </b-col> -->
      <b-col class="col-auto mr-2" v-if="!isPredictUser">
        <b-button
          size="sm"
          variant="outline-primary"
          @click="viewProjectDetails"
        >
          {{ $t("button.details") }}
        </b-button>
      </b-col>
      <b-col class="col-auto mr-2" v-if="isPredictUser">
        <b-button size="sm" variant="outline-primary" @click="cloneProject">
          {{ $t("button.cloneProject") }}
        </b-button>
      </b-col>
      <b-col class="col-auto mr-2" v-if="isPredictUser">
        <b-button size="sm" variant="outline-primary" @click="moveProject">
          {{ $t("button.moveProject") }}
        </b-button>
      </b-col>
      <b-col class="col-auto">
        <b-button
          size="sm"
          variant="outline-red"
          @click="openDeleteConfirmationDialog"
        >
          {{ $t("button.delete") }}
        </b-button>
      </b-col>
    </b-row>
    <ClientModal
      :modalStatus="clientModalStatus"
      :modalText="modalText"
      :callbackFn="modalCallback"
      :activeClientId="clientId"
      @submit="fun"
      @setModalStatus="setClientModalStatus"
    />
  </b-card>
</template>

<script>
import ClientModal from "./ClientModal.vue";
export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
    branch: {
      type: Object,
    },
    userType: {
      type: String,
    },
    clientId:{
      type:String,
    }

  },
  components: {
    ClientModal,
  },
  data() {
    return {
      userModal: false,
      modalText: "",
      modalCallback: null,
    };
  },

  computed: {
    clientModalStatus() {
      return this.userModal;
    },
    isResearchOnly() {
      return this.project.type === "researchonly";
    },
    branchName() {
      return this.branch.name;
    },
    isPredictUser() {
      return this.project.type === "predict";
    },
  },
  methods: {
    fun(ret) {
      console.log("Got something: ", ret);
    },
    viewProjectDetails() {
      if (this.project.type === "researchonly") {
        this.$router.push({
          name: "ClientNewProject",
          params: {
            projectId: this.project._id,
            clientId: this.project.clientId,
          },
        });
      } else {
        this.$router.push({
          name: "ProjectDetails",
          params: {
            projectId: this.project._id,
            clientId: this.project.clientId,
          },
        });
      }
    },
    preview() {
      this.$store.dispatch("analysis/RESET");
      this.$router.push({
        name: "ranking",
        params: {
          projectId: this.project._id,
          clientId: this.project.clientId,
        },
      });
    },

    cloneProject() {
      this.openClientModal(this.$t("clientModal.titleClone"), this.handleClone);
    },
    moveProject() {
      this.openClientModal(this.$t("clientModal.titleMove"), this.handleMove);
    },

    handleClone(client) {
      const srcClientId = this.clientId
      const dstClientId = client._id
      const projectId = this.project._id
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch('projects/cloneProjectToClient',{srcClientId,dstClientId,projectId})
        .then(() => {
          return this.$store.dispatch(
            "clients/retrieveProjectsByClientId",
            srcClientId
          );
        })
        .catch((error)=> console.log(error))
        .finally(() => {
          this.$store.dispatch("loader/close");
        });
    },

    handleMove(client) {
      const srcClientId = this.clientId
      const dstClientId = client._id
      const projectId = this.project._id
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch('projects/moveProjectToClient',{srcClientId,dstClientId,projectId})
        .then(() => {
          return this.$store.dispatch(
            "clients/retrieveProjectsByClientId",
            srcClientId
          );
        })
        .catch((error)=> console.log(error))
        .finally(() => {
          this.$store.dispatch("loader/close");
        });
      
    },

    openClientModal(text, callback) {
      this.modalText = text;
      this.modalCallback = callback;
      this.setClientModalStatus(true);
    },

    setClientModalStatus(status) {
      this.userModal = status;
    },
    openDeleteConfirmationDialog() {
      this.$store.commit("confirm/SET_CONFIRM", {
        titleText: "dialog.confirm.deleteProjectTitle",
        messageText: "dialog.confirm.deleteProjectMsg",
        messageParams: { projectName: this.project.name },
        confirmBtnCallback: this.deleteProject,
        type: "DELETE",
      });
    },
    deleteProject() {
      const projectId = this.project._id;
      const clientId = this.project.clientId;

      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("projects/deleteProjectByClientId", { clientId, projectId })
        .then(() => {
          return this.$store.dispatch(
            "clients/retrieveProjectsByClientId",
            clientId
          );
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.$store.dispatch("loader/close");
        });
    },
  },
};
</script>

<style></style>
