<template>
  <b-modal
    v-model="isModalVisible"
    size="md"
    dialog-class="mt-md-5 p-0"
    content-class="p-2"
    footer-class="border-top-0"
    header-class="border-bottom-0"
    @ok="submit"
    @cancel="cancel"
  >
    <template v-slot:modal-header>
      <div style="width: 100%;">
        <h5>{{ modalText }}</h5>
        <SearchBar :placeholder="$t('clientModal.searchPlaceHolder')+'...'" :value="searchQuery" @input="searchQuery = $event" style="width: 100%;" class="mt-4"/>
      </div>
    </template>
    <template v-slot:default>
      <div class="client-list-container">
        <button
          v-for="client in clientList"
          :key="client._id"
          style="width: 100%"
          class="no-decoration-btn mt-1"
          @click="selectClient(client)"
        >
        <b-card no-body class="p-3 mb-2"
                :class="`${client === selectedClient?'selected':''}`"
          >
          <b-row class="no-gutters align-items-center">
            <b-col class="col-auto mr-3">
              <img :src="clientTypeImg" class="user__icon" />
            </b-col>
            <b-col>
              <div class="font-weight-bold">
                {{ client.firstName }} {{ client.lastName }}
              </div>
              <div class="text-muted small">
                {{ client.registration?.email }}
              </div>
            </b-col>
          </b-row>
        </b-card>
        </button>
      </div>
    </template>
    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button
        variant="white"
        class="mr-3 btn-blue-border justify-content-center align-items-center bold"
        @click="cancel"
      >
        {{ $t("button.cancel") }}
      </b-button>
      <b-button
        variant="dark"
        class="justify-content-center align-items-center bold"
        @click="ok"
      >
        {{ $t("button.ok") }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import SearchBar from '../SearchBar.vue';
export default {
  props: {
    modalStatus: {
      type: Boolean,
      required: true,
    },
    modalText: {
      type: String,
      required: true,
    },
    callbackFn: {
      type: Function,
      required: true,
    },
    activeClientId:{
      type:String,
      required:true
    }
  },
  components:{
    SearchBar
  },
  data() {
    return {
    selectedClient: {},
    searchQuery: "",
  };
  },
  computed: {
    clientList() {
    return this.$store.getters["clients/getClientList"]
      .filter(client => client._id !== this.activeClientId)
      .filter(client => {
        const fullName = `${client.firstName} ${client.lastName}`.toLowerCase();
        const email = client.registration?.email?.toLowerCase() || "";
        return (
          fullName.includes(this.searchQuery.toLowerCase()) ||
          email.includes(this.searchQuery.toLowerCase())
        );
      });
  },
    isModalVisible: {
      get() {
        return this.modalStatus;
      },
      set(modalStatus) {
        this.$emit("setModalStatus", modalStatus);
      },
    },
    clientTypeImg() {
      if (this.isClientMultiuser) {
        return require("./../../assets/icons/users.svg");
      } else {
        return require("./../../assets/icons/user.svg");
      }
    },
  },
  methods: {
    submit() {
      if (this.callbackFn) {
        this.callbackFn(this.selectedClient);
      }
      this.selectedClient = {};
    },
    cancel() {
      this.selectedClient = {};
    },
    selectClient(client) {
      this.selectedClient = client;
    },
  },
};
</script>

<style>
.user__icon {
  width: 20px;
  filter: invert(0%) sepia(85%) saturate(7500%) hue-rotate(173deg)
    brightness(87%) contrast(113%);
}
.no-decoration-btn {
  padding: 0;
  border: none;
  background: none;
  box-shadow: none;
  text-decoration: none;
}
.no-decoration-btn:hover,
.no-decoration-btn:focus,
.no-decoration-btn:active {
  padding: 0;
  border: none;
  background: none;
  box-shadow: none;
  text-decoration: none;
  color: inherit; 
  outline: none;
}
.selected{
    background-color: #0331517b;
}

.client-list-container {
  max-height: 525px;
  overflow-y: auto;
  padding-right: 5px;
}

.client-list-container::-webkit-scrollbar {
  width: 6px;
}

.client-list-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.client-list-container::-webkit-scrollbar-track {
  background: transparent;
}
</style>