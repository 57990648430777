<template>
  <b-form-input
    :value="value"
    :placeholder=placeholder
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  props: {
    value: String,
    placeholder:String,
  },
};
</script>
